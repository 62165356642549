import React from "react"
import { Routes } from "../../data/routes"
import CTA from "../../components/CTA"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Press",
  header: "Press",
  description: "Download Yup's media kit or contact press@yup.com",
  headerActions: [
    {
      link: "https://docs.google.com/presentation/d/1Rbq8oh98OY742pXhOiv4l7W2Im6zmNxy2_RyuzA-6hs/edit?usp=sharing",
      text: "Download media kit",
    },
    {
      link: "mailto:press@yup.com",
      text: "Email press@yup.com",
    },
  ],

  // Resources section
  resourcesHeader: "Yup in the news",
  resources: [
    {
      title: "Forbes: What Is The Best Way To Teach Problem Solving?",
      link: "https://www.forbes.com/sites/quora/2019/10/04/what-is-the-best-way-to-teach-problem-solving/#190cae5139a4",
      category: "Yup in the news",
      image: "press-resource-1.jpeg",
      imageAltText: "Partly completed jigsaw puzzle",
    },
    {
      title: "Yup Math Tutoring App Teams Up With ACES",
      link: `${Routes.blog}/mobile-math-tutoring-app-for-students`,
      category: "Yup in the news",
      image: "press-resource-2.jpeg",
      imageAltText: "Boy on TV show",
    },
    {
      title: "Help Your Child Learn Math With Practical Tips From Yup (30:18)",
      link: "https://momenough.com/wp-content/uploads/2020/01/ME-Show-1-6-20.mp3",
      category: "Podcast",
      image: "press-resource-3.jpeg",
      imageAltText: "Two teachers smiling",
    },
    {
      title: "KARE11: Math App Helps Students Calculate Success",
      link: "https://www.kare11.com/article/news/local/as-seen-on-tv/math-app-helps-students-calculate-success/89-4904e3da-d5d9-4efd-8225-6feb23bd8168",
      category: "Yup in the news",
      image: "press-resource-4.jpeg",
      imageAltText: "Young man speaking",
    },
    {
      title: "Bringing Empathy to the Digital Space Through App-Based Tutoring",
      link: `${Routes.blog}/education-leadership-judah-schwartz`,
      category: "Yup news",
      image: "press-resource-5.jpeg",
      imageAltText: "Man wearing glasses",
    },
    {
      title:
        "Forbes: Math EdTech Startup Says It’s Found Winning Formula With On-Demand Tutoring",
      link: "https://www.forbes.com/sites/mikemontgomery/2019/09/03/this-math-edtech-says-its-found-a-winning-formula-with-on-demand-tutoring/#7bd39dad716d",
      category: "Yup in the news",
      image: "press-resource-6.png",
      imageAltText: "Woman staring intently at smartphone",
    },
  ],
}

function PressResourceSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/blog-sections#component-720cf60b960fecb99c45f462f24db2d9
  return (
    <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {Content.resourcesHeader}
          </h1>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {Content.resources.map(resource => (
            <a
              key={resource.title}
              href={resource.link}
              target="_blank"
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <Img
                  className="h-48 w-full object-cover"
                  src={resource.image}
                  alt={resource.title}
                  cover={true}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm text-secondary uppercase tracking-wider font-bold">
                    {resource.category}
                  </p>
                  <p className="text-xl font-semibold text-gray-900 mt-2">
                    {resource.title}
                  </p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const Press = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.press}
      />
      <CTA title={Content.header} actions={Content.headerActions} />
      <PressResourceSection />
    </Layout>
  )
}

export default Press
